import { SlickSlider } from "components/UI/SlickSlider";
import styled from "styled-components";

export const SlideSection = () => {
  return (
    <Styles>
      <SlickSlider items={Array(10).fill("")}>
        {(item: any, ind: number) => {
          return (
            <div className="slide-item">
              <img src={`/images/slide-${ind + 1}.jpg`} />
            </div>
          );
        }}
      </SlickSlider>
    </Styles>
  );
};

const Styles = styled.div`
  width: 100%;
  margin: 50px 0;

  .slide-item {
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
`;
