import styled from "styled-components";
import { SlideSection } from "./Slide";
import { Container } from "components/layout";
import { TopSection } from "./Top";

const HomeScreen = () => {
  return (
    <Container>
      <Styles>
        <TopSection />
        <SlideSection />
      </Styles>
    </Container>
  );
};

export default HomeScreen;

const Styles = styled.div`
  padding: 50px 0;
`;
