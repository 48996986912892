import styled from "styled-components";

export const TopSection = () => {
  return (
    <Styles>
      <img src="/logo.png" className="logo" />
      <h2>Easy Reminder</h2>
      <p>Program your work and life</p>
      <p>
        Feature: Help managing your work, events by day, month, year and
        reminder
      </p>
      <div
        className="google-play"
        onClick={() => {
          window.open(
            "https://play.google.com/store/apps/details?id=com.nguyennt.easyreminder",
            "_blank"
          );
        }}
      >
        <img src="/images/google-play.png" />
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .logo {
    max-width: 160px;
    border-radius: 50%;
  }

  h2 {
    font-size: 48px;
    color: #9c27b0;
    font-weight: 700;
  }

  p {
    font-size: 18px;
  }

  .google-play {
    cursor: pointer;
    margin: 10px;
    img {
      max-width: 200px;
    }
  }
`;
