import { Container } from "components/layout";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Footer = () => {
  return (
    <Styles>
      <Container>
        <div className="footer">
          <div className="footer-left">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/privacy-policy">Privacy</NavLink>
          </div>
          <p>Copyright © 2023. All rights reserved.</p>
        </div>
      </Container>
    </Styles>
  );
};

export default Footer;

const Styles = styled.div`
  padding: 10px;
  text-align: center;
  background: black;
  color: white;
  font-size: 18px;
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .footer-left {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
  p {
    font-size: 14px;
  }
`;
