import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./home";
import { AuthLayout } from "./main";
import { PrivacyPolicyScreen } from "./privacy";

export const ScreenRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
        </Route>

        <Route path="*" element={<h1>404 No page!</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default ScreenRouter;
